.page-container {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    z-index: 902;
    padding: 4rem 16rem;
    display: flex;
    box-sizing: border-box;
    visibility: hidden;
    pointer-events: none;
}

.modal-container {
    /* background-color: rgba(0, 0, 0, 0.427); */
    /* backdrop-filter: blur(10px); */
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2rem;
    border-radius: 10px;
    color: white;
    pointer-events: all;
}

.modal-header {
    height: 10%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px rgba(255, 255, 255, 0.733) solid;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0px;
    align-items: center;
}

.modal-header .formlike-container {
    display: flex;
    gap: 1rem;
    font-size: 1.2rem;
}

.modal-header select {
    background-color: transparent;
    outline: unset;
    border: unset;
    border-bottom: 2px rgb(255, 255, 255) solid;
    font-size: 1.2rem;
    color: white;
}

.modal-header select:hover {
    background-color: #4949498f;
    outline: unset;
    border: unset;
    cursor: pointer;
}

.modal-header input {
    border: white 1px solid;
    background-color: transparent;
    color: white;
    font-weight: 700;
    text-align: center;
}

.modal-header-adsn-container {
    padding: 0px 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-header-x-icon-container {
    /* background-color:blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    ;
    /* border:1px red solid; */
    padding: 0px 2rem;
}

.modal-header-x-icon-container i {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.767);
}

.modal-header-x-icon-container i:hover {
    cursor: pointer;
    color: white;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.308);
}


.modal-table {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    border-radius: 0px 0px 5px 5px;
    display: flex;
    align-items: center;
    ;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
}

.table-entry-container:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.171);
}