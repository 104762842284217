@import "../../node_modules/leaflet/dist/leaflet.css";

.searchBar {
  /* position: absolute; */
  left: 45%;
  top: 2%;
  z-index: 900;
}

#logoutButton {
  position: absolute;
  right: 2%;
  top: 3vh;
  z-index: 900;
  background: none;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  border:none;
  background-color: rgba(0, 0, 0, 0.427);
  backdrop-filter: blur(10px);
  color:white;
}

#logoutButton:hover{
  background-color: rgba(170,170,170,0.85);
  cursor:pointer;

}
#logoutButton:active{
  background-color: rgba(140,140,140,0.9);

}
.background-for-thing {
  top:.5vh;
  position: absolute;
  left: 4rem;
  padding: 1rem;
  z-index: 901;
  background-color: rgba(0, 0, 0, 0.427);
  backdrop-filter: blur(10px);
  width: min-content;
  gap: 1rem;
  display: flex;
  flex-direction: row;
}
.leaflet-popup-content ul {
  padding-left: 0px;
}
#modal-popout-button{
  font-size:1.5rem;
  color:rgba(255, 255, 255, 0.8);
}
#modal-popout-button:hover{
  cursor: pointer;
  color:rgba(255, 255, 255, 1);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.308);
}
.open-table-container {
  font-size: 1.5rem;
  padding: .5rem;
  background-color: #f9cc00;
  border: 2px #212529 solid;
  color: #212529;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.open-table-container h5{
  margin:0px;
  font-size:.8rem;
}

.open-table-container:hover {
  background-color: #0069d9;
  color: #fff;
}