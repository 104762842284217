.table-entry-container {
    /* background-color:white; */
    height: 8%;
    width: 100%;
    display: flex;
    border-bottom: 1px rgba(255, 255, 255, 0.815) solid;
}

.flex-width {
    display: flex;
    flex: 1;
}

.table-content-container {
    display: flex;
    border-right: 1px white solid;
    justify-content: center;
    align-items: center;
    transition-duration: .25s;
}

.table-content-container:last-of-type {
    border-right: 0px;
}

.table-content-container:hover {
    background-color: white;
    color: rgba(0, 0, 0, 0.781);
    transition-duration: .25s;
}

.table-adsn-container {
    /* background-color:red; */
    width: 10%;
    /* border-left:1px white solid; */
}

.table-base-container {
    /* background-color: orange; */
}

.table-base-container:hover {
    /* background-color: orange; */
    cursor: pointer;
}

.table-organization-container {
    /* background-color:yellow; */
}

.table-zip-container {
    /* background-color: green; */
    width: 10%;
}

.table-pascode-container {
    /* background-color:blue; */
    width: 10%;
}

.table-geolocate-container {
    /* background-color: indigo; */
    /* background-color:white; */
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-geolocate-container:hover {
    cursor: pointer;
}

.table-geolocate-container i {
    /* color:rgb(0, 0, 0); */
    font-size: 1.5rem;
}