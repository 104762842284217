.login-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: url(/src/mappicture.png);
}
.login-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20vh;
  box-sizing: border-box;
  width: 100%;
  background-color: #0000002e;
  backdrop-filter: blur(10px);
}
.login-words-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #212529;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #212529;
}

.button {
  font-size: 1.5rem;
  padding: 1rem 2rem;
  background-color: #f9cc00;
  border: 4px #212529 solid;
  color: #212529;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0069d9;
  color: #fff;
}

.squadron-logo {
  width: 200px;
  height: 200px;
  margin-top: 2rem;
}
