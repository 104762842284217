body{
    height:100vh;
    width: 100vw;
    overflow: hidden;
}
.leaflet-container {
    position: absolute;
    width:100%;
    height: 100%;
}
.popup-class .leaflet-popup-content-wrapper {
    height:auto;
    max-height:40vh;
    max-width: 40vw;
    text-align: center;
}
